h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label {
  color: rgba(51, 51, 51, 0.85);
}

.modalCancelaLote .modal-dialog {
  min-width: 300px;
  text-align: center;
}

.nav-link span {
  color: white !important;
}

.sidebar-light .nav-sidebar .nav-link {
  color: white !important;
}

.nav-item-submenu > .nav-link:after {
  color: white !important;
}

.sidebar-light .nav-sidebar .nav-link:active {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.sidebar-light .nav-sidebar > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.sidebar-light .nav-sidebar .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.w-20 {
  width: 20px !important;
}

.h-20 {
  height: 20px !important;
}

.cemPor {
  width: 100%;
}

.m-0-auto {
  margin: 0 auto;
}

.h-cemPor {
  height: 100vw;
}

.h-auto {
  height: auto;
}

.p-0 {
  padding: 0;
}

.h-50px {
  height: 50px !important;
}

.h-40px {
  height: 40px !important;
}

.pt-03-em {
  padding-top: 0.3em;
}

a {
  cursor: pointer;
}

.min-w-400 {
  min-width: 400px;
}

.fs-1-5em {
  font-size: 1.5em;
}

.fs-1-7em {
  font-size: 1.7em;
}

.fs-2em {
  font-size: 2em;
}

.fs-3em {
  font-size: 3em;
}

.fs-4em {
  font-size: 4em;
}

.bt-1-ico {
  height: 40px;
  width: 60px;
  text-align: center;
  padding: 0;
}

body {
  overflow-y: auto;
}

.sidebar {
  width: 15rem;
}

* {
  outline: none !important;
}

:focus,
*:focus {
  outline: none !important;
}

.menuLateral {
  display: block;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
